import {
  batchUpdateKeyResult,
  batchUpdateObjectives,
  deleteKeyResult,
  deleteObjective,
  getStatusList,
  patchStatusList,
  getObjectivesOverview,
  getProgressStatistics,
  patchKeyResult,
  patchObjective,
  postKeyResult,
  postObjective,
  copyObjective,
  moveObjective,
  getSchedulerPlans,
  patchSchedulerPlanForYear,
  getSchedulerYearDetail,
  getSchedulerYearCalendar,
  postSchedulerYearPlan,
  postSchedule,
  patchSchedule,
  deleteSchedule,
  postScheduleReminder,
  deleteScheduleReminder,
  patchScheduleReminder,
  postSchedulerPlan,
  createMilestone,
  getAllCategories,
  deleteMilestone,
  patchMilestone,
  createCategory,
  updateCategory,
  deleteCategory,
  getChangeLogData,
  getCompany,
  patchCompany,
  getTeamAnalytics,
  getExternalTasks,
  getExternalTasksMeta
} from "../services/okrService";
import { stateError, stateInitial, stateLoaded, stateLoading, executeAsync } from "./util";

import Vue from "vue";

const initialState = {
  userOkrCategory: "existingTeamsCategories",
  statuses: [],
  statusesLoadingState: stateInitial,
  objectivesOverview: [],
  objectivesOverviewLoadingState: stateInitial,
  objectivesDetail: [],
  objectivesDetailLoadingState: stateInitial,
  objectivesProgress: [],
  objectivesProgressLoadingState: stateInitial,
  pendingKeyResultUpdates: [],
  pendingObjectiveUpdates: [],
  okrSchedulerPlans: [],
  changeLogLoadingState: stateInitial,
  changeLog: { results: [] },
  okrSchedulerPlansLoadingState: stateInitial,
  okrSchedulerYearDetail: {},
  okrSchedulerYearCalendar: [],
  okrSchedulerYearLoadingState: stateInitial,
  organizationalCategories: [],
  organizationalCategoriesLoadingState: stateInitial,
  company: null,
  filterDate: { startDate: "", endDate: "" },
  changeLogParams: {
    sort: "",
    pageNo: 1,
    itemFilter: [],
    typeFilter: [],
    timeRange: ""
  },
  teamAnalytics: [],
  teamAnalyticsLoadingState: stateInitial,
  externalTasks: {},
  externalTasksLoadingState: stateInitial,
  externalTasksMeta: null,
  externalTasksMetaLoadingState: stateInitial,
  selectedDepartment: [],
  selectedUser: [],
  selectedStatus: [],
  selectedRange: "",
  filteredMilestones: [],
  statusFilter: [],
  departmentFilter: [],
  userFilter: [],
  timerangeFilter: null,
  okrColumns: [
    {
      name: ["name"],
      displayName: "Name",
      selected: true,
      disabledDraggable: true,
      disabledCheckbox: true
    },
    { name: ["kpi"], displayName: "KPI", selected: true },
    {
      name: ["start", "target", "actual"],
      displayName: "Start, Target, Actual",
      selected: true
    },
    { name: ["progress"], displayName: "Progress", selected: true },
    { name: ["status"], displayName: "Status", selected: true },
    { name: ["updated"], displayName: "Updated", selected: true },
    { name: ["responsible"], displayName: "Responsible", selected: true, disabledDraggable: true },
    { name: ["link"], displayName: "Link", selected: true, disabledDraggable: true }
  ],
  // Tracks the current depth of the okrListView to be displayed, show key results on default
  expandItemsFilter: 1,
  // Is triggered when a the ExpandItems filter was clicked
  isExpandItemsFilterClicked: false,
  // Tracks the number of rows in the okrListView that are still being rendered
  okrTableDataRenderBuffer: 0,
  reloadOkrDetails: false,
  ignoreOkrDateUpdate: false
};

const mutations = {
  setSelectedDepartment(state, value) {
    state.selectedDepartment = JSON.parse(JSON.stringify(value));
  },
  setSelectedStatus(state, value) {
    state.selectedStatus = JSON.parse(JSON.stringify(value));
  },
  setSelectedUser(state, value) {
    state.selectedUser = JSON.parse(JSON.stringify(value));
  },
  setSelectedRange(state, value) {
    state.selectedRange = value;
  },
  setFilteredMilestones(state, value) {
    state.filteredMilestones = JSON.parse(JSON.stringify(value));
  },
  resetPageNo(state) {
    state.changeLogParams.pageNo = 1;
  },
  updateItemFilter(state, filter) {
    state.changeLogParams.itemFilter = filter;
  },
  updateTypeFilter(state, filter) {
    state.changeLogParams.typeFilter = filter;
  },
  updateTimeRange(state, timeRange) {
    state.changeLogParams.timeRange = timeRange;
  },
  objectivesOverviewLoading(state) {
    state.objectivesOverviewLoadingState = stateLoading;
  },
  changeLogLoading(state) {
    state.changeLogLoadingState = stateLoading;
  },
  organizationalCategoriesLoading(state) {
    state.organizationalCategoriesLoadingState = stateLoading;
  },
  organizationalCategoriesLoaded(state, organizationalCategoriesData) {
    state.organizationalCategories = organizationalCategoriesData;
    state.organizationalCategoriesLoadingState = stateLoaded;
  },
  changeLogLoaded(state, changeLogData) {
    state.changeLog = changeLogData;
    state.changeLogLoadingState = stateLoaded;
  },
  changeLogError(state, error) {
    state.changeLogLoadingState = stateError;
    state.changeLogLoadingState.errorMessage = error;
  },
  statusesLoading(state) {
    state.statusesLoadingState = stateLoading;
  },
  statusesLoaded(state, objectiveStatusData) {
    state.statuses = objectiveStatusData;
    state.statusesLoadingState = stateLoaded;
  },
  statusesError(state, error) {
    state.statusesLoadingState = stateError;
    state.statusesLoadingState.errorMessage = error;
  },
  organizationalCategoriesError(state, error) {
    state.organizationalCategoriesLoadingState = stateError;
    state.organizationalCategoriesLoadingState.errorMessage = error;
  },
  createOrganizationalCategories(state, organizationalCategory) {
    state.organizationalCategories.push(organizationalCategory);
  },
  postChangeLog(state, changeLogData) {
    state.changeLog.push(changeLogData);
  },
  deleteOrganizationalCategories(state, id) {
    for (var i = 0; i < state.organizationalCategories.length; i++) {
      if (state.organizationalCategories[i].id == id) {
        state.organizationalCategories.splice(i, 1);
      }
    }
  },
  objectivesOverviewLoaded(state, data) {
    state.objectivesOverview = data;
    state.objectivesOverviewLoadingState = stateLoaded;
  },
  objectivesOverviewError(state, error) {
    state.objectivesOverviewLoadingState = stateError;
    if (error.response.data.detail) {
      state.objectivesOverviewLoadingState.errorMessage = error.response.data.detail;
    } else if (error.response.data.non_field_errors) {
      state.objectivesOverviewLoadingState.errorMessage = error.response.data.non_field_errors[0];
    } else {
      state.objectivesOverviewLoadingState.errorMessage = error;
    }
  },
  objectivesOverviewReset(state) {
    state.objectivesOverviewLoadingState = stateInitial;
  },
  removeObjective(state, objectiveId) {
    state.okrTableData.nodes.forEach(
      department =>
        (department.nodes = department.nodes.filter(objective => objective.data.id !== objectiveId))
    );
  },
  removeKeyResult(state, keyResultId) {
    state.okrTableData.nodes.forEach(department =>
      department.nodes.forEach(
        objective =>
          (objective.nodes = objective.nodes.filter(keyresult => keyresult.data.id !== keyResultId))
      )
    );
  },
  objectivesProgressLoading(state) {
    state.objectivesProgressLoadingState = stateLoading;
  },
  objectivesProgressLoaded(state, data) {
    state.objectivesProgress = data;
    state.objectivesProgressLoadingState = stateLoaded;
  },
  okrColumnsUpdate(state, column) {
    state.okrColumns = JSON.parse(JSON.stringify(column));
  },
  objectivesProgressError(state, error) {
    state.objectivesProgressLoadingState = stateError;
    state.objectivesProgressLoadingState.errorMessage = error;
  },
  teamAnalyticsLoading(state) {
    state.teamAnalyticsLoadingState = stateLoading;
  },
  teamAnalyticsLoaded(state, data) {
    state.teamAnalytics = data;
    state.teamAnalyticsLoadingState = stateLoaded;
  },
  teamAnalyticsError(state, error) {
    state.teamAnalyticsLoadingState = stateError;
    state.teamAnalyticsLoadingState.errorMessage = error;
  },

  setObjectiveParent(state, { child, parent }) {
    for (let category in state.objectivesOverview) {
      const item = state.objectivesOverview[category].objectives.find(obj => obj.id === child.id);
      if (item !== undefined) {
        item.parent = parent?.id;
        break;
      }
    }
  },
  locallyEditKeyResult(state, { value, key, item }) {
    // These are local edits that are not yet being send to the server
    const updateItem = state.pendingKeyResultUpdates.find(update => update.id === item.id);
    if (updateItem) {
      if (value === null || value.length == 0) {
        Vue.delete(updateItem, key);
      } else {
        Vue.set(updateItem, key, value);
      }
    } else {
      state.pendingKeyResultUpdates.push({
        [key]: value,
        id: item.id,
        metrics: item.metrics
      });
    }
  },
  locallyEditObjective(state, { value, key, item }) {
    // These are local edits that are not yet being send to the server
    // Note: though we can currenttly only edit the objective name, this is meant to support arbitrary objective properties
    const updateItem = state.pendingObjectiveUpdates.find(update => update.id === item.id);
    if (updateItem) {
      if (value === null || value.length == 0) {
        Vue.delete(updateItem, key);
      } else {
        Vue.set(updateItem, key, value);
      }
    } else {
      state.pendingObjectiveUpdates.push({
        [key]: value,
        id: item.id
      });
    }
  },
  okrSchedulerYearLoading(state) {
    state.okrSchedulerYearLoadingState = stateLoading;
  },
  okrSchedulerYearLoaded(state, { detailData, calendarData }) {
    state.okrSchedulerYearDetail = detailData;
    state.okrSchedulerYearCalendar = calendarData;
    state.okrSchedulerYearLoadingState = stateLoaded;
  },
  okrSchedulerYearError(state, error) {
    state.okrSchedulerYearLoadingState = stateError;
    state.okrSchedulerYearLoadingState.errorMessage = error;
  },
  okrSchedulerPlansLoading(state) {
    state.okrSchedulerPlansLoadingState = stateLoading;
  },
  okrSchedulerPlansLoaded(state, data) {
    state.okrSchedulerPlans = data;
    state.okrSchedulerPlansLoadingState = stateLoaded;
  },
  okrSchedulerPlansError(state, error) {
    state.okrSchedulerPlansLoadingState = stateError;
    state.okrSchedulerPlansLoadingState.errorMessage = error;
  },
  setUserOkrCategory(state, categoryName) {
    state.userOkrCategory = categoryName;
  },
  updateCompany(state, company) {
    state.company = company;
  },
  changeFilterDate(state, dates) {
    state.changeLogParams.pageNo = 1;
    state.filterDate.startDate = dates.startDate;
    state.filterDate.endDate = dates.endDate;
  },
  changeChangeLogSort(state, sort) {
    state.changeLogParams.sort = sort;
  },
  incrementPageNo(state) {
    state.changeLogParams.pageNo += 1;
  },
  decrementPageNo(state) {
    state.changeLogParams.pageNo -= 1;
  },
  externalTasksLoaded(state, { data, origin, project }) {
    if (!project) {
      state.externalTasks = data;
    } else {
      state.externalTasks[origin][project] = data;
    }
  },
  externalTasksMetaLoading(state) {
    state.externalTasksMetaLoadingState = stateLoading;
  },
  externalTasksMetaLoaded(state, data) {
    // Metadata loaded:
    // we have full list of task origins and projects
    state.externalTasksMeta = data;
    state.externalTasksMetaLoadingState = stateLoaded;
  },
  updateStatusFilter(state, { updatedStatusFilterArray }) {
    state.statusFilter = updatedStatusFilterArray;
  },
  updateDepartmentFilter(state, { updatedDepartmentFilterArray }) {
    state.departmentFilter = updatedDepartmentFilterArray;
  },
  updateUserFilter(state, { updatedUserFilterArray }) {
    state.userFilter = updatedUserFilterArray;
  },
  updateTimerangeFilter(state, { updatedTimerangeFilter }) {
    state.timerangeFilter = updatedTimerangeFilter;
  },
  updateOkrTableDataNodesForPath(state, { nodes, path }) {
    // Go over the ids in the `path`
    // the length of the `path` indicates the nesting level of the nodes

    // reference to the node whose nodes should be updated
    let cursor = state.okrTableData;
    for (let id of path) {
      cursor = cursor.nodes.find(node => node.data.id === id);
    }

    // update of the nodes
    cursor.nodes = nodes;
  },
  updateExpandItemsFilter(state, expandedDepth) {
    state.expandItemsFilter = expandedDepth;
  },
  toggleIsExpandItemsFilterClicked(state) {
    // Will set the state to `true`
    // This will trigger the render/ unrender of the rows in the OkrListView
    state.isExpandItemsFilterClicked = true;

    // Switch it back to `false` after the signal was passed.
    // A small amount of ms is already enough to trigger the vue reactivity
    executeAsync(() => {
      state.isExpandItemsFilterClicked = false;
    }, 10);
  },
  pushOkrTableDataRenderBuffer(state) {
    state.okrTableDataRenderBuffer++;
  },
  popOkrTableDataRenderBuffer(state) {
    state.okrTableDataRenderBuffer--;
  },

  updateOkrVisibleKpis(state, { id, shouldRemove }) {
    const index = state.okrVisibleKpis.indexOf(id);

    if (index >= 0) {
      // if already present, check if it needs to be removed,
      // otherwise ignore it
      if (shouldRemove) {
        state.okrVisibleKpis.splice(index, 1);
      }
    } else {
      // if it's not present yet, add it
      state.okrVisibleKpis.push(id);
    }
  },

  setReloadOkrDetails(state) {
    state.reloadOkrDetails = !state.reloadOkrDetails;
  },

  setIgnoreOkrDateUpdate(state, value) {
    if (typeof value !== "boolean") {
      value = Boolean(value);
    }
    state.ignoreOkrDateUpdate = value;
  }
};

const actions = {
  changeFilter(context, params) {
    context.state.changeLogParams.pageNo = 1;
    context.commit("changeFilterDate", params);

    if (window.location.pathname.includes("/objectives/changelog")) {
      context.dispatch("loadChangeLogData");
    }
  },
  loadChangeLogData(context) {
    const params = {};
    const sort = context.state.changeLogParams.sort;
    if (sort.length > 0 && sort == "Oldest to newest") {
      params.order = "old_first";
    }
    const startDate = context.state.filterDate.startDate;
    if (startDate.length > 0) {
      params.startDate = startDate;
    }
    const endDate = context.state.filterDate.endDate;
    if (endDate.length > 0) {
      params.endDate = endDate;
    }
    const itemFilter = context.state.changeLogParams.itemFilter;
    if (itemFilter?.length > 0) {
      params["action_type"] = itemFilter.toString();
    }
    const typeFilter = context.state.changeLogParams.typeFilter;
    if (typeFilter?.length > 0) {
      params["item_type"] = typeFilter.toString();
    }
    const timeRange = context.state.changeLogParams.timeRange;
    if (timeRange?.length > 0) {
      params.timeRange = timeRange;
    }
    params.p = context.state.changeLogParams.pageNo;

    getChangeLogData(params).then(
      response => {
        // concatenate first name and surname together
        context.commit("changeLogLoaded", response.data);
      },
      error => {
        context.commit("changeLogError", error);
      }
    );
  },
  deleteOrganizationalCategories(context, id) {
    deleteCategory(id).then(() => {
      context.commit("deleteOrganizationalCategories", id);
    });
  },
  updateOrganizationalCategories(context, payload) {
    updateCategory(payload).then(() => {
      context.dispatch("loadOrganizationalCategoriesData");
    });
  },
  postOrganizationalCategory(context, payload) {
    createCategory(payload).then(response => {
      context.commit("createOrganizationalCategories", response.data);
    });
  },

  loadOrganizationalCategoriesData(context) {
    context.commit("organizationalCategoriesLoading");
    getAllCategories().then(
      response => {
        context.commit("organizationalCategoriesLoaded", response.data);
      },
      error => {
        context.commit("organizationalCategoriesError", error);
      }
    );
  },
  loadObjectivesOverview(context, { startDate, endDate }) {
    context.commit("objectivesOverviewLoading");
    context.dispatch("statusList");
    getObjectivesOverview(startDate, endDate).then(
      response => {
        context.commit("objectivesOverviewLoaded", response.data);
      },
      error => {
        context.commit("objectivesOverviewError", error);
      }
    );
  },
  createMilestone(context, milestone) {
    createMilestone(milestone).then(() => context.commit("setReloadOkrDetails"));
  },
  deleteMilestone(context, milestoneId) {
    deleteMilestone(milestoneId).then(() => {
      context.commit("setReloadOkrDetails");
    });
  },
  patchMilestone(context, { patch }) {
    if (patch.responsibleUser) {
      patch = { ...patch, responsibleUser: patch.responsibleUser.id };
    }

    patchMilestone(patch).then(() => context.commit("setReloadOkrDetails"));
  },
  statusList(context) {
    context.commit("statusesLoading");
    getStatusList().then(
      response => {
        context.commit("statusesLoaded", response.data);
      },
      error => {
        context.commit("statusesError", error);
      }
    );
  },
  patchStatusList(context, statusList) {
    patchStatusList(statusList).then(() => {
      context.dispatch("statusList");
    });
  },
  loadObjectivesProgress(context, query) {
    context.commit("objectivesProgressLoading");
    getProgressStatistics(query).then(
      response => {
        context.commit("objectivesProgressLoaded", response.data);
      },
      error => {
        context.commit("objectivesProgressError", error);
      }
    );
  },
  loadTeamAnalytics(context, query) {
    context.commit("teamAnalyticsLoading");
    getTeamAnalytics(query).then(
      response => {
        context.commit("teamAnalyticsLoaded", response.data);
      },
      error => {
        context.commit("teamAnalyticsError", error);
      }
    );
  },
  createObjective(context, payload) {
    return new Promise((resolve, reject) => {
      postObjective(payload.createObjective).then(
        response => {
          context.commit("setReloadOkrDetails");
          context.dispatch("loadObjectivesOverview", {
            startDate: payload.objectivesOverViewDates.startDate,
            endDate: payload.objectivesOverViewDates.dueDate
          });
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  copyObjective(context, { sourceId, objective, query, objectivesOverViewDates }) {
    return new Promise((resolve, reject) => {
      copyObjective(sourceId, objective, query).then(
        response => {
          context.commit("setReloadOkrDetails");
          context.dispatch("loadObjectivesOverview", {
            startDate: objectivesOverViewDates.startDate,
            endDate: objectivesOverViewDates.dueDate
          });
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  moveObjective(context, { sourceId, objective, query, objectivesOverViewDates }) {
    return new Promise((resolve, reject) => {
      moveObjective(sourceId, objective, query).then(
        response => {
          context.commit("setReloadOkrDetails");
          context.dispatch("loadObjectivesOverview", {
            startDate: objectivesOverViewDates.startDate,
            endDate: objectivesOverViewDates.dueDate
          });
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  patchObjective(context, { patch }) {
    if (patch.responsibleUser) {
      patch = { ...patch, responsibleUser: patch.responsibleUser.id };
    }

    return new Promise((resolve, reject) => {
      patchObjective(patch).then(
        response => {
          context.commit("setReloadOkrDetails");
          resolve(response);
        },
        error => {
          context.commit("objectivesOverviewError", error);
          reject(error);
        }
      );
    });
  },
  deleteObjective(context, objectiveId) {
    return new Promise((resolve, reject) =>
      deleteObjective(objectiveId).then(
        response => {
          context.commit("objectivesOverviewReset");
          resolve(response);
        },
        error => {
          context.commit("objectivesOverviewError", error);
          reject(error);
        }
      )
    );
  },
  createKeyResult(context, { keyResult }) {
    return new Promise((resolve, reject) => {
      postKeyResult(keyResult).then(
        response => {
          context.commit("setReloadOkrDetails");
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  batchUpdateKeyResults(context, updates) {
    return new Promise((resolve, reject) => {
      batchUpdateKeyResult(updates).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  batchUpdateObjectives(context, updates) {
    return new Promise((resolve, reject) => {
      batchUpdateObjectives(updates).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  patchKeyResult(context, { patch }) {
    // We follow an optimistic approach: we change the data locally and try to update in the background. If the background fails the user should be informed
    // Since we don't know the side effects here, we return a promise that can be used to dispatch reloads

    if (patch.responsibleUser) {
      patch = { ...patch, responsibleUser: patch.responsibleUser.id };
    }
    return new Promise((resolve, reject) => {
      patchKeyResult(patch).then(
        response => {
          context.commit("setReloadOkrDetails");
          resolve(response);
        },
        error => {
          context.commit("objectivesOverviewError", error);
          reject(error);
        }
      );
    });
  },
  deleteKeyResult(context, keyResultId) {
    return new Promise((resolve, reject) =>
      deleteKeyResult(keyResultId).then(
        response => {
          context.commit("objectivesOverviewReset");
          resolve(response);
        },
        error => {
          context.commit("objectivesOverviewError", error);
          reject(error);
        }
      )
    );
  },

  commitKeyResultChanges(context, { data, payload }) {
    // commit a single local key result change, if successful this deletes the change from the local list of changes
    return new Promise((resolve, reject) => {
      context
        .dispatch("patchKeyResult", {
          patch: {
            ...data
          },
          shouldReload: true,
          payload
        })
        .then(
          () => {
            resolve();
          },
          error => {
            context.commit("objectivesOverviewError", error);
            context.commit("objectivesDetailError", error);
            reject(error);
          }
        );
    });
  },
  commitObjectiveChanges(context, { data, payload }) {
    return new Promise((resolve, reject) => {
      context
        .dispatch("patchObjective", {
          patch: {
            ...data
          },
          shouldReload: true,
          payload: payload || {}
        })
        .then(
          () => {
            resolve();
          },
          error => {
            context.commit("objectivesOverviewError", error);
            context.commit("objectivesDetailError", error);
            reject(error);
          }
        );
    });
  },
  loadOkrSchedulerPlans(context) {
    context.commit("okrSchedulerPlansLoading");

    getSchedulerPlans().then(
      response => {
        context.commit("okrSchedulerPlansLoaded", response.data);
      },
      error => {
        context.commit("okrSchedulerPlansError", error);
      }
    );
  },
  createOkrSchedulerPlan(context, schedulePlan) {
    return new Promise((resolve, reject) => {
      postSchedulerPlan(schedulePlan).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  setUserOkrCategory(context, payload) {
    context.commit("setUserOkrCategory", payload.userOkrCategory);
  },
  loadOkrSchedulerYear(context, year) {
    context.commit("okrSchedulerYearLoading");

    getSchedulerYearDetail(year).then(
      detailResponse => {
        getSchedulerYearCalendar(year).then(
          calendarResponse => {
            context.commit("okrSchedulerYearLoaded", {
              detailData: detailResponse.data,
              calendarData: calendarResponse.data
            });
          },
          error => {
            context.commit("okrSchedulerYearError", error);
          }
        );
      },
      error => {
        if (error.response.status == 404) {
          // Create new plan for year if not existing
          postSchedulerYearPlan(year).then(
            () => {
              context.dispatch("loadOkrSchedulerYear", year);
            },
            createError => {
              context.commit("okrSchedulerYearError", createError);
            }
          );
        } else {
          context.commit("okrSchedulerYearError", error);
        }
      }
    );
  },
  createSchedule(context, schedule) {
    return new Promise((resolve, reject) => {
      postSchedule(schedule).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  deleteSchedule(context, id) {
    return new Promise((resolve, reject) =>
      deleteSchedule(id).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      )
    );
  },
  patchSchedule(state, schedule) {
    return new Promise((resolve, reject) => {
      patchSchedule(schedule).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  createScheduleReminder(context, reminder) {
    return new Promise((resolve, reject) => {
      postScheduleReminder(reminder).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  deleteScheduleReminder(context, id) {
    return new Promise((resolve, reject) =>
      deleteScheduleReminder(id).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      )
    );
  },
  patchScheduleReminder(state, reminder) {
    return new Promise((resolve, reject) => {
      patchScheduleReminder(reminder).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  patchSchedulerPlanForYear(state, { year, id }) {
    return new Promise((resolve, reject) => {
      patchSchedulerPlanForYear(year, id).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getCompany(context) {
    getCompany().then(response => context.commit("updateCompany", response.data));
  },
  patchCompany(context, company) {
    patchCompany(company.id, {
      name: company.name
    }).then(() => {
      context.dispatch("getCompany");
    });
  },

  getExternalTasks(context, { origin, project }) {
    if (!project) {
      return new Promise((resolve, reject) =>
        getExternalTasks(origin, project).then(
          response => {
            context.commit("externalTasksLoaded", {
              data: response.data
            });
            resolve(response);
          },
          error => reject(error)
        )
      );
    } else {
      // Cache origin-project pairs, to avoid repeating the same request
      if (
        !context.state.externalTasks ||
        !(origin in context.state.externalTasks) ||
        !(project in context.state.externalTasks[origin])
      ) {
        if (!(origin in context.state.externalTasks)) {
          context.state.externalTasks[origin] = {};
        }
        context.state.externalTasks[origin][project] = [];
        return new Promise((resolve, reject) =>
          getExternalTasks(origin, project).then(
            response => {
              context.commit("externalTasksLoaded", {
                data: response.data,
                origin,
                project
              });
              resolve(response);
            },
            error => reject(error)
          )
        );
      }
    }

    return new Promise(resolve => resolve());
  },
  getExternalTasksMeta(context) {
    if (
      !context.state.externalTasksMetaLoadingState.loaded &&
      !context.state.externalTasksMetaLoadingState.loading
    ) {
      context.commit("externalTasksMetaLoading");
      return new Promise((resolve, reject) => {
        getExternalTasksMeta().then(response => {
          context.commit("externalTasksMetaLoaded", response.data);
          resolve(response);
        });
        error => reject(error);
      });
    }
    return new Promise(resolve => resolve());
  },
  updateStatusFilter(context, { updatedStatusFilterArray }) {
    context.commit("updateStatusFilter", { updatedStatusFilterArray });
  },
  updateDepartmentFilter(context, { updatedDepartmentFilterArray }) {
    context.commit("updateDepartmentFilter", { updatedDepartmentFilterArray });
  },
  updateUserFilter(context, { updatedUserFilterArray }) {
    context.commit("updateUserFilter", { updatedUserFilterArray });
  },
  updateTimerangeFilter(context, { updatedTimerangeFilter }) {
    context.commit("updateTimerangeFilter", { updatedTimerangeFilter });
  },
  resetOkrFilters(context) {
    context.dispatch("updateStatusFilter", { updatedStatusFilterArray: [] });
    context.dispatch("updateDepartmentFilter", {
      updatedDepartmentFilterArray: []
    });
    context.dispatch("updateUserFilter", { updatedUserFilterArray: [] });
    context.dispatch("updateTimerangeFilter", { updatedTimerangeFilter: "" });
  },
  updateOkrTableDataNodesForPath(context, { orderedNodesList }) {
    // The `orderedNodesList` is a list of objects of the form:
    // { path: [...], nodes: [...] }
    //
    // where path indicates the nesting level, containing the ids of every parent node
    // and nodes is the same nodes from the vuex but in the new order

    // DRY: dispatcher map based on the nesting level of the nodes
    const dispatchersMap = [null, "batchUpdateObjectives", "batchUpdateKeyResults"];

    for (let index in orderedNodesList) {
      let { path, nodes } = orderedNodesList[index];

      // update the position in the node.data
      nodes = nodes.map((node, position) => {
        return { ...node, data: { ...node.data, position } };
      });

      // call the path on the backend
      context
        .dispatch(
          dispatchersMap[path.length],
          nodes.map(node => {
            return { id: node.data.id, position: node.data.position };
          })
        )
        .then(
          // commit the changes to the vuex as well on success
          () => {
            context.commit("setReloadOkrDetails");
          },
          // otherwise commit the errors
          error => {
            context.commit("objectivesOverviewError", error);
            context.commit("objectivesDetailError", error);
          }
        );
    }
  },
  updateExpandItemsFilter(context, depthValue) {
    // Update the new depth to be filtered by, and then trigger the render/ unrender of the rows in the OkrListView
    context.commit("updateExpandItemsFilter", depthValue);
    context.commit("toggleIsExpandItemsFilterClicked");
  }
};

const getters = {
  statusList: state => state.statuses,
  statusListLoadingState: state => state.statusesLoadingState,
  okrColumns: state => state.okrColumns,
  selectedDepartmentState: state => state.selectedDepartment,
  selectedStatusState: state => state.selectedStatus,
  selectedUserState: state => state.selectedUser,
  selectedRangeState: state => state.selectedRange,
  selectedFilteredMilestonesState: state => state.filteredMilestones,
  objectivesOverview: state => state.objectivesOverview,
  objectivesOverviewLoadingState: state => state.objectivesOverviewLoadingState,
  objectivesProgress: state => state.objectivesProgress,
  objectivesProgressLoadingState: state => state.objectivesProgressLoadingState,
  okrSchedulerYearDetail: state => state.okrSchedulerYearDetail,
  okrSchedulerYearCalendar: state => state.okrSchedulerYearCalendar,
  okrSchedulerYearLoadingState: state => state.okrSchedulerYearLoadingState,
  okrSchedulerPlans: state => state.okrSchedulerPlans,
  okrSchedulerPlansLoadingState: state => state.okrSchedulerPlansLoadingState,
  organizationalCategoriesLoadingState: state => state.organizationalCategoriesLoadingState,
  organizationalCategories: state => state.organizationalCategories,
  userOkrCategory: state => state.userOkrCategory,
  changeLogLoadingState: state => state.changeLogLoadingState,
  changeLog: state => state.changeLog,
  changeLogCount: state => state.changeLog.count,
  startDate: state => state.okrDate.startDate,
  endDate: state => state.okrDate.endDate,
  count: state => state.changeLog.count,
  pageNo: state => state.changeLogParams.pageNo,
  isDecrement: state => state.changeLogParams.pageNo <= 1,
  isIncrement: state => {
    const maxPage = state.changeLog.count / 50;
    return state.changeLogParams.pageNo >= maxPage;
  },
  pendingKeyResultUpdates: state => state.pendingKeyResultUpdates,
  company: state => state.company,
  teamAnalytics: state => state.teamAnalytics,
  teamAnalyticsLoadingState: state => state.teamAnalyticsLoadingState,
  externalTasks: state => state.externalTasks,
  externalTasksLoadingState: state => state.externalTasksLoadingState,
  externalTasksMeta: state => state.externalTasksMeta,
  externalTasksMetaLoadingState: state => state.externalTasksMetaLoadingState,

  okrTableDataRenderBuffer: state => state.okrTableDataRenderBuffer,
  okrVisibleKpis: state => state.okrVisibleKpis,

  statusFilter: state => state.statusFilter,
  departmentFilter: state => state.departmentFilter,
  userFilter: state => state.userFilter,
  timerangeFilter: state => state.timerangeFilter,
  expandItemsFilter: state => state.expandItemsFilter,
  isExpandItemsFilterClicked: state => state.isExpandItemsFilterClicked,

  okrFilter: state => {
    return {
      departmentFilter: state.departmentFilter,
      statusFilter: state.statusFilter,
      userFilter: state.userFilter,
      timerangeFilter: state.timerangeFilter
    };
  },

  reloadOkrDetails: state => state.reloadOkrDetails,

  ignoreOkrDateUpdate: state => state.ignoreOkrDateUpdate
};

export const okrModule = {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations
};
